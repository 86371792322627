import { JSX, useRef } from "react";
import { MusicChannel } from "./channel";

type ChannelPickerProps = {
  channels: MusicChannel[];
  channel?: MusicChannel;
  onChange: (channel: MusicChannel) => void;
};

function ChannelPicker(props: ChannelPickerProps): JSX.Element {
  const pickerElement = useRef<null | HTMLDetailsElement>(null);

  const onChoose = (channel: MusicChannel) => {
    props.onChange(channel);
    pickerElement?.current?.removeAttribute("open");
  };

  const options: JSX.Element[] = props.channels.map((channel: MusicChannel) => {
    return (
      <li key={channel.id} onClick={() => onChoose(channel)}>
        {channel.tags.join(", ")}
        <small>
          {channel.album} - {channel.artist}
        </small>
      </li>
    );
  });

  return (
    <details
      role="list"
      className="music-picker"
      id="music-picker"
      ref={pickerElement}
    >
      <summary aria-haspopup="listbox">
        <div>
          {props.channel?.tags.join(",")}
          <small>
            {props.channel?.album} - {props.channel?.artist}
          </small>
        </div>
      </summary>
      <ul role="listbox">{options}</ul>
    </details>
  );
}

export default ChannelPicker;
