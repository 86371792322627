import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Gallery() {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate("/");
      return;
    }
  }, [state, navigate]);

  if (!state) {
    return;
  }

  const { slides } = state;

  return (
    <div>
      <Lightbox
        open={true}
        close={() => navigate("/relax")}
        slides={slides}
        plugins={[Download]}
      />
    </div>
  );
}

export default Gallery;
