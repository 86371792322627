import "../scss/photo.scss";
import { Trans } from "react-i18next";
import { ChangeEvent } from "react";
import Cake from "./photo/cake";
import Landscape from "./photo/landscape";
import storage from "../storage/storage";
import { StorageKey } from "../storage/storage-key";
import { PhotoMode } from "./photo/mode";
import { useAppDispatch, useAppSelector } from "../store";
import { setMode } from "./photo/photo-state";

function Photo(): JSX.Element {
  const mode: PhotoMode | undefined = useAppSelector(
    (state) => state.photo.mode,
  );
  const dispatch = useAppDispatch();

  const onModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newMode: PhotoMode = event.target.value as PhotoMode;

    dispatch(setMode(newMode));
    storage.set(StorageKey.PhotoMode, newMode);
  };

  let content: JSX.Element;

  switch (mode) {
    case PhotoMode.Cake:
      content = <Cake />;
      break;
    case PhotoMode.Landscape:
    default:
      content = <Landscape />;
      break;
  }

  return (
    <div className="box photo">
      <div className="photo-choice">
        <label htmlFor="photo-landscape">
          <input
            type="radio"
            id="photo-landscape"
            name="photo"
            value="landscape"
            checked={mode === PhotoMode.Landscape}
            onChange={onModeChange}
          />
          <Trans i18nKey="photoChoiceLandscape">Landscape</Trans>
        </label>
        <label htmlFor="photo-cake">
          <input
            type="radio"
            id="photo-cake"
            name="photo"
            value="cake"
            checked={mode === PhotoMode.Cake}
            onChange={onModeChange}
          />
          <Trans i18nKey="photoChoiceCake">Cake</Trans>
        </label>
      </div>
      {content}
    </div>
  );
}

export default Photo;
