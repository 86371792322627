import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../data/i18n/en.json";
import fr from "../data/i18n/fr.json";
import es from "../data/i18n/es.json";
import zh from "../data/i18n/zh.json";
import LocaleDetector from "./locale-detector";
import { Environnement } from "../configuration/environnement";
import config from "../configuration/config";
import storage from "../storage/storage";
import { StorageKey } from "../storage/storage-key";

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
  zh: {
    translation: zh,
  },
};

i18n
  .use(initReactI18next)
  .use(LocaleDetector)
  .init({
    resources,
    debug: config.getEnv() === Environnement.Development,
    interpolation: {
      escapeValue: false,
    },
  });

const updateLocale = (lang: string) => {
  document.documentElement.setAttribute("lang", lang);
  storage.set(StorageKey.Locale, lang);
};

i18n.on("languageChanged", (lng) => {
  updateLocale(lng);
});

i18n.on("initialized", () => {
  updateLocale(i18n.language);
});

export default i18n;
