import { ThemeMode } from "./theme-mode";
import storage from "../../storage/storage";
import { StorageKey } from "../../storage/storage-key";

export default class ThemeSelector {
  private static DefaultTheme: ThemeMode = ThemeMode.Light;

  getCurrent(): ThemeMode {
    if (storage.has(StorageKey.Theme)) {
      return storage.get(StorageKey.Theme) as ThemeMode;
    }

    if (document.documentElement.dataset.theme === "dark") {
      return ThemeMode.Dark;
    }

    if (document.documentElement.dataset.theme === "light") {
      return ThemeMode.Light;
    }

    if (!window.matchMedia) {
      return ThemeSelector.DefaultTheme;
    }

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return ThemeMode.Dark;
    }

    return ThemeSelector.DefaultTheme;
  }
}
