import "../scss/relax.scss";
import Music from "../module/music";
import Photo from "../module/photo";
import Theme from "../module/theme";
import Feeling from "../module/feeling";
import AppearAfter from "../module/appear-after";
import Article from "../module/article";
import { Outlet } from "react-router-dom";

function Relax(): JSX.Element {
  return (
    <div className="relax">
      <aside className="sidebar">
        <AppearAfter delayInMs={500}>
          <Photo />
        </AppearAfter>
        <AppearAfter delayInMs={1400}>
          <Music />
        </AppearAfter>
        <AppearAfter delayInMs={3000}>
          <Feeling />
        </AppearAfter>
        <AppearAfter delayInMs={2700}>
          <Theme />
        </AppearAfter>
      </aside>
      <AppearAfter delayInMs={800}>
        <main>
          <Article />
        </main>
      </AppearAfter>
      <Outlet />
    </div>
  );
}

export default Relax;
