import "yet-another-react-lightbox/styles.css";
import { Trans } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";

export interface ToolbarProps {
  credits?: JSX.Element;
  gallery: GallerySource[];
}

export interface GallerySource {
  src: string;
  downloadFilename?: string;
}

function Toolbar(props: ToolbarProps) {
  const linkState = {
    slides: props.gallery,
  };

  return (
    <div className="toolbar">
      <Link to="/relax/gallery" state={linkState} className="gallery">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M200 32H56C42.7 32 32 42.7 32 56V200c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l40-40 79 79-79 79L73 295c-6.9-6.9-17.2-8.9-26.2-5.2S32 302.3 32 312V456c0 13.3 10.7 24 24 24H200c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-40-40 79-79 79 79-40 40c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H456c13.3 0 24-10.7 24-24V312c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2l-40 40-79-79 79-79 40 40c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H312c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l40 40-79 79-79-79 40-40c6.9-6.9 8.9-17.2 5.2-26.2S209.7 32 200 32z"
          />
        </svg>
        <Trans i18nKey="photoGalleryButton">Gallery</Trans>
      </Link>

      <small className="credits">{props.credits}</small>
    </div>
  );
}

export default Toolbar;
