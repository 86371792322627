import "../scss/kofi.scss";
import { Trans, useTranslation } from "react-i18next";
import config from "../configuration/config";
import { ConfigurationKey } from "../configuration/configuration-key";

function KoFi() {
  const url: string = config.get(ConfigurationKey.KofiUrl);
  const { t } = useTranslation();

  return (
    <a
      className="kofi"
      href={url}
      target="_blank"
      role="button"
      rel="noreferrer"
    >
      <Trans i18nKey="buyMeACoffee" t={t}>
        Buy me a Coffee
      </Trans>
    </a>
  );
}

export default KoFi;
