import "../scss/feeling.scss";
import { Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../store";
import { Feel } from "./feeling/feel";
import { setCurrent } from "./feeling/feeling-state";

function Feeling() {
  const feelings: Feel[] = useAppSelector((state) => state.feeling.feelings);
  const current: Feel | undefined = useAppSelector(
    (state) => state.feeling.current,
  );
  const dispatch = useAppDispatch();

  const chooseFeeling = (feel: Feel) => {
    dispatch(setCurrent(feel));

    fetch(`/feeling?feeling=${feel.key}`).catch((e) => console.error(e));
  };

  const items = feelings.map((feel: Feel) => {
    const classes: string[] = ["feeling"];

    if (feel.emoji === current?.emoji) {
      classes.push("active");
    }

    return (
      <div
        key={feel.emoji}
        className={classes.join(" ")}
        onClick={() => chooseFeeling(feel)}
      >
        <strong>{feel.emoji}</strong>
        <small>
          <Trans i18nKey={feel.i18nKey}></Trans>
        </small>
      </div>
    );
  });

  let explain: JSX.Element | null = null;

  if (current) {
    explain = (
      <div className="explain">
        <Trans i18nKey="feelingsExplain">
          Simply being aware of your state of mind is a positive step.
        </Trans>
      </div>
    );
  }

  return (
    <div className="box feeling">
      <Trans i18nKey="feelingsTitle">
        Take a few seconds, then state how you feel:
      </Trans>
      <div className="feels">{items}</div>
      {explain}
    </div>
  );
}

export default Feeling;
