import { EmptyArrayError } from "../error/empty-array-error";

export class ArrayUtil {
  static getRandomValue<T>(arr: T[]): T {
    if (arr.length === 0) {
      throw new EmptyArrayError();
    }

    return arr[Math.floor(Math.random() * arr.length)];
  }

  static shuffleArray<T>(arr: T[]): T[] {
    let currentIndex = arr.length,
      randomIndex;

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [arr[currentIndex], arr[randomIndex]] = [
        arr[randomIndex],
        arr[currentIndex],
      ];
    }

    return arr;
  }
}
