import { ThemeMode } from "./theme-mode";
import storage from "../../storage/storage";
import { StorageKey } from "../../storage/storage-key";

export default class ThemeUpdater {
  setTheme(theme: ThemeMode): void {
    document.documentElement.dataset.theme = theme.toString();
    storage.set(StorageKey.Theme, theme.toString());
  }
}
