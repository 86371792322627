import "./../scss/about.scss";
import { Trans } from "react-i18next";
import KoFi from "../module/kofi";

function About() {
  return (
    <div className="about">
      <h1>
        <Trans i18nKey="aboutTitle">About</Trans>
      </h1>
      <p>
        <Trans i18nKey="about1"></Trans>
      </p>
      <p>
        <Trans i18nKey="about2"></Trans>
      </p>
      <p>
        <Trans i18nKey="about3">
          abreakfrom.work was developed by Jonathan P., a web developer with an
          interest in psychology and well-being. If you like this site, please
          feel free to <KoFi></KoFi>.
        </Trans>
      </p>
    </div>
  );
}

export default About;
