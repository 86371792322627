import { createSlice } from "@reduxjs/toolkit";
import { ThemeMode } from "./theme-mode";
import ThemeSelector from "./theme-selector";
import ThemeUpdater from "./theme-updater";

interface ThemeState {
  value: ThemeMode;
}

const initialState: ThemeState = {
  value: new ThemeSelector().getCurrent(),
};

new ThemeUpdater().setTheme(initialState.value);

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setLight: (state) => {
      state.value = ThemeMode.Light;
    },
    setDark: (state) => {
      state.value = ThemeMode.Dark;
    },
  },
});

export const { setLight, setDark } = themeSlice.actions;

export default themeSlice.reducer;
