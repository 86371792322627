import { PropsWithChildren, useEffect, useState } from "react";

export interface AppearAfterProps extends PropsWithChildren {
  delayInMs: number;
}

function AppearAfter(props: AppearAfterProps) {
  const [classes, setClasses] = useState<string[]>(["appear-after"]);

  useEffect(() => {
    setTimeout(() => {
      setClasses(["appear-after", "now-visible"]);
    }, props.delayInMs);
  }, [props.delayInMs]);

  return <div className={classes.join(" ")}>{props.children}</div>;
}

export default AppearAfter;
