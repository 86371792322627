import "../scss/footer.scss";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import KoFi from "./kofi";
import LocalePicker from "./footer/locale-picker";

function Footer() {
  const year: number = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <footer>
      <ul>
        <li>
          <LocalePicker></LocalePicker>
        </li>
        <li>
          <Link to="/about">{t("footerAbout")}</Link>
        </li>
        <li>
          <Trans i18nKey="footerCopyright" year={year} t={t}>
            Copyright <Link to="/">abreakfrom.work</Link> {{ year }}
          </Trans>
        </li>
        <li>
          <KoFi></KoFi>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
