import { createSlice } from "@reduxjs/toolkit";
import storage from "../../storage/storage";
import { StorageKey } from "../../storage/storage-key";
import { PhotoMode } from "./mode";

interface PhotoState {
  mode?: PhotoMode;
}

const initialState: PhotoState = {
  mode: storage.get(StorageKey.PhotoMode) as PhotoMode | undefined,
};

export const photoSlice = createSlice({
  name: "photo",
  initialState,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { setMode } = photoSlice.actions;

export default photoSlice.reducer;
