import { Detector } from "./detector";

export class BrowserDetector extends Detector {
  public detect(): string | undefined {
    if (navigator.languages) {
      const lang: string = navigator.languages[0];

      if (lang) {
        return lang.substring(0, 2);
      }
    }

    const lang: string = navigator.language;

    if (lang) {
      return lang.substring(0, 2);
    }
  }
}
