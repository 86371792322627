import "./../scss/not-found.scss";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="not-found">
      <h1>
        <Trans i18nKey="notFoundTitle">Page not found</Trans>
      </h1>

      <Link to={"/"} role="button">
        <Trans i18nKey="notFoundBack">Back to home page</Trans>
      </Link>
    </div>
  );
}

export default NotFound;
