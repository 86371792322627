export class HtmlCleaner {
  private selectorsToDelete: string[] = [
    ".infobox",
    ".mw-editsection",
    "sup.reference",
    ".reflist",
    ".navbox",
    ".hatnote",
    ".side-box",
    "span#References",
    ".sidebar",
    ".metadata",
    "a.external",
    ".quotebox",
    ".mw-kartographer-map",
  ];

  clean(html: string, language: string): string {
    const element: HTMLElement = document.createElement("div");
    element.innerHTML = html;

    this.selectorsToDelete.forEach((selector: string) => {
      this.remove(element.querySelectorAll(selector));
    });

    element.querySelectorAll("p a").forEach((a: Element) => {
      a.replaceWith(a.textContent as string);
    });

    element.querySelectorAll("a").forEach((a: HTMLAnchorElement) => {
      a.href =
        `https://${language}.wikipedia.org/wiki/` +
        a.href.split("/wiki/").pop();

      a.target = "_blank";
    });

    return element.innerHTML;
  }

  private remove(html: Element | NodeListOf<Element>): void {
    if (html instanceof HTMLElement) {
      return html.remove();
    }

    if (html instanceof NodeList) {
      html.forEach((entry: Element) => {
        if (!entry) {
          return;
        }

        entry.remove();
      });
    }
  }
}
