import { MouseEventHandler } from "react";
import { Trans } from "react-i18next";

interface ReadAnotherProps {
  onClick: MouseEventHandler;
  className?: string;
  disabled: boolean;
}

function ReadAnother(props: ReadAnotherProps) {
  return (
    <button
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled}
    >
      <Trans i18nKey="wikiReadAnother">Read another article</Trans>
    </button>
  );
}

export default ReadAnother;
