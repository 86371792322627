import { Detector } from "./detector";
import { ConfigurationKey } from "../../configuration/configuration-key";
import config from "../../configuration/config";

export class QueryStringDetector extends Detector {
  public detect(): string | undefined {
    const params: URLSearchParams = new URLSearchParams(window.location.search);
    const key: string = config.get(ConfigurationKey.LocaleQueryKey);

    if (params.has(key)) {
      const locale = params.get(key);

      if (locale !== null) {
        return locale;
      }
    }

    return undefined;
  }
}
