import "../../scss/cake.scss";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Toolbar, { GallerySource } from "./toolbar";
import Loading from "../loading";
import { ArrayUtil } from "../../util/array-util";

const cakeUpperLimit: number = 230;

function Cake() {
  const { t } = useTranslation();
  const [cake, setCake] = useState<string>();

  const getRandomCakeUrl = (): string => {
    const cakeNumber: number = Math.ceil(Math.random() * (cakeUpperLimit - 1));

    return formatCakeUrl(cakeNumber);
  };

  const getGallery = (): string[] => {
    const urls: string[] = [];

    for (let i = 0; i < cakeUpperLimit; i++) {
      urls.push(formatCakeUrl(i));
    }

    ArrayUtil.shuffleArray(urls);

    return urls.slice(0, 100);
  };

  const formatCakeUrl = (cakeNumber: number): string => {
    return "/cakes/" + cakeNumber.toString(10).padStart(3, "0") + ".jpg";
  };

  const fetchCake = async () => {
    setCake(getRandomCakeUrl());
  };

  useEffect(() => {
    setCake(getRandomCakeUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content: JSX.Element = <Loading />;

  if (cake) {
    const credits = (
      <Trans i18nKey="cakeUnsplashThanks">
        Photos by
        <a href="https://unsplash.com/" target="_blank" rel="noreferrer">
          Unsplash
        </a>
      </Trans>
    );

    const createGallerySource = (url: string): GallerySource => {
      return {
        src: url,
        downloadFilename: "abreakfrom_work_cake",
      };
    };

    const urls: GallerySource[] = getGallery().map((src: string) => {
      return createGallerySource(src);
    });

    urls.unshift(createGallerySource(cake));

    content = (
      <div>
        <img src={cake} onClick={fetchCake} alt={t("cakeImgAlt")} />
        <Toolbar credits={credits} gallery={urls} />
      </div>
    );
  }

  return <div className="cake">{content}</div>;
}

export default Cake;
