import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./module/layout";
import Landing from "./page/landing";
import Relax from "./page/relax";
import About from "./page/about";
import NotFound from "./page/not-found";
import Gallery from "./module/photo/gallery";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/relax" element={<Relax />}>
            <Route path="/relax/gallery" element={<Gallery />} />
          </Route>
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
