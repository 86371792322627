import { ModuleType } from "i18next";
import { LocaleMap } from "./locale-map";
import { Detector } from "./detector/detector";
import { ConfigurationKey } from "../configuration/configuration-key";
import { QueryStringDetector } from "./detector/query-string-detector";
import { LocalStorageDetector } from "./detector/local-storage-detector";
import { BrowserDetector } from "./detector/browser-detector";
import config from "../configuration/config";

class LocaleDetector {
  // i18next module properties
  static readonly type: ModuleType = "languageDetector";
  static readonly async: boolean = false;

  detect(): string {
    const detectors: Detector[] = this.getDetectors();
    const locales: LocaleMap = new LocaleMap();
    let locale: string = this.getDefaultLocale();

    for (const detector of detectors) {
      const detected = detector.detect();

      if (!detected) {
        continue;
      }

      if (locales.isValid(locale)) {
        locale = detected;
        break;
      }
    }

    return locale;
  }

  private getDetectors(): Detector[] {
    return [
      new QueryStringDetector(),
      new LocalStorageDetector(),
      new BrowserDetector(),
    ];
  }

  private getDefaultLocale(): string {
    return config.get(ConfigurationKey.DefaultLocale);
  }
}

export default LocaleDetector;
