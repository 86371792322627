import { Trans } from "react-i18next";
import { MouseEventHandler } from "react";

interface Props {
  accept: MouseEventHandler;
}

function Cookies(props: Props) {
  return (
    <div className="not-allowed">
      <button onClick={props.accept}>
        <Trans i18nKey="musicAllowCookies">
          Click here to allow Bandcamp cookies and start the music.
        </Trans>
      </button>
    </div>
  );
}

export default Cookies;
