import { ConfigurationKey } from "../configuration/configuration-key";
import config from "../configuration/config";

export class LocaleMap {
  getAll(): string[] {
    return config.get(ConfigurationKey.Locales).split(",");
  }

  isValid(locale: string): boolean {
    return this.getAll().includes(locale);
  }
}
