import "../scss/music.scss";
import { JSX, useEffect, useState } from "react";
import channelsJson from "../data/music-channels.json";
import storage from "../storage/storage";
import { StorageKey } from "../storage/storage-key";
import Cookies from "./music/cookies";
import { MusicChannel } from "./music/channel";
import ChannelPicker from "./music/channel-picker";
import Loading from "./loading";
import { BrowserUtil } from "../util/browser-util";
import { ArrayUtil } from "../util/array-util";

const channels: MusicChannel[] = channelsJson;

function Music(): JSX.Element {
  const [channel, setChannel] = useState<MusicChannel>();
  const [allowed, setAllowed] = useState<boolean>(false);

  useEffect(() => {
    const randomChannel: MusicChannel = ArrayUtil.getRandomValue(channels);
    setChannel(randomChannel);

    const storageAllowed: string | undefined = storage.get(
      StorageKey.MusicCookies,
    );

    if (storageAllowed === "1") {
      setAllowed(true);
    }
  }, []);

  const change = (chan: MusicChannel) => {
    setChannel(chan);
  };

  const acceptCookies = () => {
    storage.set(StorageKey.MusicCookies, "1");
    setAllowed(true);
  };

  if (!channel) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  let content: JSX.Element;

  if (allowed) {
    const backgroundColor: string =
      BrowserUtil.getCssVariableValue("--box-background").substring(1);

    const src: string = `https://bandcamp.com/EmbeddedPlayer/album=${channel.id}/size=large/bgcol=${backgroundColor}/linkcol=0687f5/tracklist=false/artwork=small/`;

    content = (
      <div className="player-container">
        <iframe src={src} seamless title="Music Player"></iframe>
      </div>
    );
  } else {
    content = <Cookies accept={acceptCookies} />;
  }

  return (
    <div className="music box">
      <ChannelPicker
        channel={channel}
        onChange={change}
        channels={channels}
      ></ChannelPicker>
      {content}
    </div>
  );
}

export default Music;
