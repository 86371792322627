import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

function ShareModal() {
  const [id, setId] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [copied, setIsCopied] = useState<boolean>(false);
  const { i18n } = useTranslation();

  document.addEventListener("open-share", (e: Event) => {
    const id: string = (e as CustomEvent).detail.id;

    setId(id);
    setIsOpen(true);
  });

  if (!id) {
    return;
  }

  const url: string = `https://abreakfrom.work/${i18n.language}.html?article=${id}`;

  const copy = async () => {
    await navigator.clipboard.writeText(url);
    setIsCopied(true);

    setTimeout(() => {
      setIsOpen(false);
    }, 5000);
  };

  const closeModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen(false);
  };

  let copyButton = (
    <button onClick={() => copy()}>
      <Trans i18nKey="shareModalCopy">Copy</Trans>
    </button>
  );

  if (copied) {
    copyButton = (
      <button className="outline">
        <Trans i18nKey="shareModalCopied">Copied! 👍</Trans>
      </button>
    );
  }

  if (!window.isSecureContext) {
    copyButton = <span />;
  }

  return (
    <dialog open={isOpen} className="share-modal">
      <article>
        <header>
          <a
            href="#close"
            aria-label="Close"
            className="close"
            onClick={closeModal}
          >
            <Trans i18nKey="modalClose">Close</Trans>
          </a>
          <Trans i18nKey="shareModalTitle">Share this page</Trans>
        </header>
        <div>
          <input
            value={url}
            type="text"
            onFocus={(event) => event.target.select()}
          />
          {copyButton}
        </div>
      </article>
    </dialog>
  );
}

export default ShareModal;
