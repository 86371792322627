import "../scss/landing.scss";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, MouseEvent as ReactMouseEvent } from "react";

function Landing() {
  const [classes, setClasses] = useState<string[]>(["begin"]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("documentDefaultTitle");
  }, [t]);

  const onClick = (e: ReactMouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setClasses(["begin", "start"]);
    setTimeout(() => {
      navigate("/relax");
    }, 800);
  };

  return (
    <div className="landing">
      <a
        href="/relax"
        className={classes.join(" ")}
        role="button"
        onClick={onClick}
      >
        <em>☕</em> <Trans i18nKey="landingMainCTA">Start break</Trans>
      </a>
    </div>
  );
}

export default Landing;
