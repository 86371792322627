import "../../scss/cake.scss";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Toolbar, { GallerySource } from "./toolbar";
import Loading from "../loading";
import { ArrayUtil } from "../../util/array-util";

const landscapeUpperLimit: number = 1500;

function Landscape() {
  const { t } = useTranslation();
  const [landscape, setLandscape] = useState<string>();

  const getRandomLandscapeUrl = (): string => {
    const landscapeNumber: number = Math.ceil(
      Math.random() * (landscapeUpperLimit - 1),
    );

    return formatLandscapeUrl(landscapeNumber);
  };

  const getGallery = (): string[] => {
    const urls: string[] = [];

    for (let i = 0; i < landscapeUpperLimit; i++) {
      urls.push(formatLandscapeUrl(i));
    }

    ArrayUtil.shuffleArray(urls);

    return urls.slice(0, 100);
  };

  const formatLandscapeUrl = (landscapeNumber: number): string => {
    return "/landscapes/" + landscapeNumber.toString(10) + ".jpg";
  };

  const fetchLandscape = async () => {
    setLandscape(getRandomLandscapeUrl());
  };

  useEffect(() => {
    setLandscape(getRandomLandscapeUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!landscape) {
    return (
      <div className="landscape">
        <Loading />
      </div>
    );
  }
  const createGallerySource = (url: string): GallerySource => {
    return {
      src: url,
      downloadFilename: "abreakfrom_work_landscape",
    };
  };

  const urls: GallerySource[] = getGallery().map((src: string) => {
    return createGallerySource(src);
  });

  urls.unshift(createGallerySource(landscape));

  const credits = (
    <Trans i18nKey="cakeFlickrThanks">
      Photos by
      <a href="https://www.flickr.com/" target="_blank" rel="noreferrer">
        flickr
      </a>
    </Trans>
  );

  return (
    <div className="landscape">
      <div>
        <img
          src={landscape}
          onClick={fetchLandscape}
          alt={t("landscapeImgAlt")}
        />
        <Toolbar credits={credits} gallery={urls} />
      </div>
    </div>
  );
}

export default Landscape;
