import { HtmlCleaner } from "./html-cleaner";
import { useTranslation } from "react-i18next";
import { ArticleEntity } from "../article/article-entity";

export interface BodyProps {
  article: ArticleEntity;
}

function Body(props: BodyProps) {
  const { i18n } = useTranslation();

  const cleaner: HtmlCleaner = new HtmlCleaner();
  const cleanedHtml: string = cleaner.clean(
    props.article.content,
    i18n.language,
  );

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: cleanedHtml,
      }}
    ></div>
  );
}

export default Body;
