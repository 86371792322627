import { Environnement } from "./environnement";
import { ConfigurationEntryNotFoundError } from "../error/configuration-entry-not-found-error";
import { ConfigurationKey } from "./configuration-key";

export class Configuration {
  getEnv(): Environnement {
    switch (process.env.NODE_ENV) {
      case "production":
        return Environnement.Production;
      case "test":
        return Environnement.Test;
      case "development":
      default:
        return Environnement.Development;
    }
  }

  get(configKey: ConfigurationKey): string {
    const index: string = this.formatKey(configKey);
    const value: string | undefined = process.env[index];

    if (value === undefined) {
      throw new ConfigurationEntryNotFoundError(
        `Configuration key ${configKey} (${index} in .env) not found.`,
      );
    }

    return value;
  }

  private formatKey(configKey: ConfigurationKey): string {
    return `REACT_APP_${configKey}`;
  }
}
