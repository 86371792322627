import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ArticleEntity } from "./article-entity";
import store from "../../store";

interface ArticleState {
  id?: string;
  article?: ArticleEntity;
  loading: boolean;
}

const parseIdInQueryUrl = (): string | undefined => {
  const params: URLSearchParams = new URLSearchParams(window.location.search);

  if (params.has("article")) {
    return params.get("article")?.toString();
  }
};

export const fetchArticle = createAsyncThunk(
  "article/fetch",
  async (locale: string) => {
    let url: string = `/wikipedia?locale=${locale}`;
    const id: string | undefined = store.getState().article.id;

    if (id) {
      url = `/wikipedia?id=${id}`;
    }

    const response: Response = await fetch(url);

    return await response.json();
  },
);

const initialState: ArticleState = {
  id: parseIdInQueryUrl(),
  loading: false,
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setArticle: (state, action) => {
      state.article = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArticle.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchArticle.fulfilled, (state, action) => {
      state.article = action.payload;
      state.loading = false;
      state.id = undefined;
    });

    builder.addCase(fetchArticle.rejected, (state) => {
      state.loading = false;
      state.article = undefined;
      state.id = undefined;
    });
  },
});

export const { setId, setArticle } = articleSlice.actions;

export default articleSlice.reducer;
