import { createSlice } from "@reduxjs/toolkit";
import { Feel } from "./feel";
import feelingsData from "../../data/feelings.json";
import { ArrayUtil } from "../../util/array-util";

interface FeelingState {
  feelings: Feel[];
  current?: Feel;
}

const getShuffledFeelings = () => {
  const feelings: Feel[] = feelingsData;
  ArrayUtil.shuffleArray(feelings);

  return feelings;
};

const initialState: FeelingState = {
  feelings: getShuffledFeelings(),
};

export const feelingSlice = createSlice({
  name: "feeling",
  initialState,
  reducers: {
    shuffle: (state) => {
      state.feelings = getShuffledFeelings();
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { shuffle, setCurrent } = feelingSlice.actions;

export default feelingSlice.reducer;
