import "../scss/wikipedia.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchArticle } from "./article/article-state";
import ReadAnother from "./wikipedia/read-another";
import ShareButton from "./share/share-button";
import Body from "./wikipedia/body";
import { ArticleEntity } from "./article/article-entity";
import Loading from "./loading";

function Article() {
  const { i18n } = useTranslation();
  const article: ArticleEntity | undefined = useAppSelector(
    (state) => state.article.article,
  );
  const isLoading: boolean = useAppSelector((state) => state.article.loading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchArticle(i18n.language));
  }, [dispatch, i18n.language]);

  const reload = async () => {
    const hadArticle: boolean = article != null;

    dispatch(fetchArticle(i18n.language));

    if (hadArticle) {
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    const title: HTMLElement | null = document.querySelector(".layout main");

    if (title) {
      // this timeout is a firefox hack
      setTimeout(() => {
        title.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 100);
    }
  };

  if (!article) {
    return (
      <aside className="wikipedia">
        <Loading />
      </aside>
    );
  }

  return (
    <aside className="wikipedia">
      <header>
        <h1>{article.title_out}</h1>
        <div className="toolbar">
          <ReadAnother
            onClick={reload}
            className="outline small"
            disabled={isLoading}
          ></ReadAnother>
          <ShareButton
            article={article}
            className="outline small"
          ></ShareButton>
        </div>
      </header>
      <Body article={article} />
      <div className="toolbar">
        <ReadAnother onClick={reload} disabled={isLoading}></ReadAnother>
        <ShareButton article={article}></ShareButton>
      </div>
    </aside>
  );
}

export default Article;
