import { Trans } from "react-i18next";

function Loading() {
  return (
    <Trans i18nKey="moduleLoading" aria-busy="true">
      Loading...
    </Trans>
  );
}

export default Loading;
