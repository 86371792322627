import "../scss/theme.scss";
import { Trans } from "react-i18next";
import { ChangeEvent } from "react";
import { ThemeMode } from "./theme/theme-mode";
import store, { useAppSelector } from "../store";
import ThemeUpdater from "./theme/theme-updater";
import { setDark, setLight } from "./theme/theme-state";

function Theme() {
  const theme: ThemeMode = useAppSelector((state) => state.theme.value);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const themeUpdater: ThemeUpdater = new ThemeUpdater();

    if (event.target.checked) {
      store.dispatch(setDark());
      return themeUpdater.setTheme(ThemeMode.Dark);
    }

    store.dispatch(setLight());
    return themeUpdater.setTheme(ThemeMode.Light);
  };

  return (
    <div className="theme">
      <label htmlFor="theme">
        <input
          type="checkbox"
          id="theme"
          name="theme"
          role="switch"
          checked={theme === ThemeMode.Dark}
          onChange={onChange}
        />
        <Trans i18nKey="darkLightSwitch">Dark theme</Trans>
      </label>
    </div>
  );
}

export default Theme;
