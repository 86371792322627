import {
  combineReducers,
  configureStore,
  EnhancedStore,
} from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
import themeReducer from "./module/theme/theme-state";
import articleReducer from "./module/article/article-state";
import photoReducer from "./module/photo/photo-state";
import feelingReducer from "./module/feeling/feeling-state";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const rootReducer = combineReducers({
  theme: themeReducer,
  article: articleReducer,
  photo: photoReducer,
  feeling: feelingReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store: EnhancedStore = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
