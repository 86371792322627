import { Trans } from "react-i18next";

import { ArticleEntity } from "../article/article-entity";

interface ShareButtonProps {
  article: ArticleEntity;
  className?: string;
}

function ShareButton(props: ShareButtonProps) {
  const open = () => {
    const event: CustomEvent = new CustomEvent("open-share", {
      detail: {
        id: props.article.id,
      },
    });

    document.dispatchEvent(event);
  };

  return (
    <button className={props.className} onClick={open}>
      <Trans i18nKey="shareButton">Share</Trans>
    </button>
  );
}

export default ShareButton;
