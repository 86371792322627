import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LocaleMap } from "../../i18n/locale-map";
import { ChangeEvent } from "react";
import { StringUtil } from "../../util/string-util";

function LocalePicker() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const localeMap: LocaleMap = new LocaleMap();

  const onChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    await i18n.changeLanguage(e.target.value);
    navigate("/");
  };

  const getLocaleName = (code: string): string => {
    const languageNames = new Intl.DisplayNames([code], {
      type: "language",
    });

    const name: string = languageNames.of(code) ?? "?";

    return StringUtil.capitalize(name);
  };

  interface LocaleOption {
    code: string;
    name: string;
  }

  const locales: LocaleOption[] = localeMap.getAll().map((code: string) => {
    return {
      code: code,
      name: getLocaleName(code),
    };
  });

  const options: JSX.Element[] = locales.map((locale: LocaleOption) => (
    <option value={locale.code} key={locale.code}>
      {locale.name}
    </option>
  ));

  return (
    <select value={i18n.language} className="locale-picker" onChange={onChange}>
      {options}
    </select>
  );
}

export default LocalePicker;
