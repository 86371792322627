import { StorageKey } from "./storage-key";

export class Storage {
  has(key: StorageKey): boolean {
    if (!this.isEnabled()) {
      return false;
    }

    return window.localStorage.getItem(key) !== null;
  }

  get(key: StorageKey): string | undefined {
    if (!this.isEnabled()) {
      return;
    }

    return window.localStorage.getItem(key) ?? undefined;
  }

  set(key: StorageKey, value: string) {
    if (!this.isEnabled()) {
      return;
    }

    window.localStorage.setItem(key, value);
  }

  private isEnabled(): boolean {
    return !!window.localStorage;
  }
}

const storage: Storage = new Storage();

export default storage;
