import "../scss/layout.scss";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import ShareModal from "./share/share-modal";

function Layout() {
  return (
    <div className="layout">
      <div className="main">
        <Outlet />
      </div>
      <Footer />
      <ShareModal />
    </div>
  );
}

export default Layout;
